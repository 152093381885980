.bnb-layout__header {
  background-color: #ffffff;
  color: #424242;
  /*border-top: 4px solid #FF7F00;*/
  /*border-top: 2px solid #f2f2f2;*/
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  /*TODO: check box sizes*/
  min-height: 48px;
}

.mdl-layout__header-row,
.bnb-layout__header-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 16px;
  height: 48px;
}

@media screen and (max-width: 1024px) {

  .mdl-layout__header-row,
  .bnb-layout__header-row {
    padding: 0 8px;
  }
}

.mdl-layout__header-row a,
.bnb-layout__header-row a,
.mdl-layout__header-row button,
.bnb-layout__header-row button {
  /*color: #FFFFFF !important;*/
  /*color: inherit;*/
  text-transform: none;
  text-decoration: none;
  font-weight: normal;
  padding: 0 8px;
}

@media screen and (max-width: 479px) {

  .mdl-layout__header-row a,
  .bnb-layout__header-row a,
  .mdl-layout__header-row button,
  .bnb-layout__header-row button {
    padding: 0 16px;
  }
}

.bnb-header-headings {
  /*margin-left: 20px;*/
  font-size: 1.1rem;
  /*font-family: BrandonTextWeb-Light, sans-serif;*/
  color: #999;
  line-height: normal;
}

.bnb-heading-current {
  /*font-family: BrandonTextWeb-Medium, sans-serif;*/
  font-weight: 600;
}

.mdl-menu__container {
  max-height: calc(100vh - 75px);
  overflow: auto;
}

/*end_header-row*/

.bnb--color-red {
  color: #f44336 !important;
}

.bnb--color-green {
  color: #4caf50 !important;
}

.color-gray {
  color: #707070;
}

.bnb--color-menu,
.bnb-button.bnb--color-menu {
  color: #666;
}

.bnb--color-menu:hover,
.bnb-button.bnb--color-menu:hover {
  color: #111;
}

.bnb--bg-white {
  background-color: white !important;
}

.bnb-hr_color_light {
  border-color: #eeeeee;
}

/*.bnb-stars {
    float: right;
    margin: 0;
    padding: 0;
    margin-right: 1em;
    height: 18px;
    background: url(/images/stars.png);
}*/

/*#bnb-owner-menu, #bnb-language-menu, #bnb-login-menu {
    color: #fafafa !important;
    text-transform: none !important;
}*/

/*.mdl-layout__header-row a, .mdl-layout__header-row button {
  color: #fafafa !important;
  text-transform: none !important;
}*/

::selection {
  background: #FFF176;
  text-shadow: none;
}

.bnb-text-highlighted-color {
  color: #333;
  font-weight: 600;
}

.bnb-text-bold {
  font-weight: 600;
}

.bnb-center-content {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.bnb-text-center {
  text-align: center;
}

.bnb-auth-icon {
  height: 1.2em;
  padding-bottom: .2em;
  padding-right: .2em;
}

.bnb-icon--middle {
  vertical-align: middle;
}

.bnb-display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.bnb-flex-space-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.bnb-flex-space-around {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.bnb-flex-align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bnb-flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.bnb-flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.bnb-margin-auto {
  margin: auto;
}

.bnb-content-max-width {
  max-width: 900px;
}

.bnb-content-container-min-height {
  min-height: 90vh;
}

.bnb-margin-top-medium {
  margin-top: 1em;
}

.bnb-margin-vertical-small {
  margin: .15em auto;
}

.whitespace-pre-line,
.bnb-white-space-pre-line {
  white-space: pre-line;
}

.bnb-background-white {
  background-color: #fafafa !important;
}

.bnb-owner-only-bar {
  background-color: #eeeeee;
  /* #EDE7F6 #b2dfdb #FFC107 */
  margin: 0;
  padding: 6px 8px;
}

.bnb-button--outline {
  border: solid 1px;
  /*2px*/
  border-radius: 2px;
}

p {
  line-height: 1.5em;
}

h1 {
  font-size: 1.4em;
  /*1.6*/
  line-height: 1.3;
  margin: .15em auto;
}

h2 {
  font-size: 1.3em;
  /*1.5em*/
  line-height: 1.2;
  margin: .15em auto;
}

h3 {
  font-size: 1.2em;
  line-height: 1.0;
  margin: .15em auto;
}

h4,
h5,
h6 {
  font-size: 1.2em;
  line-height: 1.0;
  margin: .15em auto;
}

ul,
ol {
  font-size: 1rem;
  /*.95rem;*/
  /*TODO check lists*/
  line-height: 1.5;
  list-style: none;
  /*TODO: check effect*/
  padding-left: 0;
  /*TODO: check*/
  margin: 0;
}

.bnb-highlight-free-benefit {
  color: white;
  background-color: #4CAF50;
  /*    padding: .1em .5em; */
}

.bnb-logo-smile {
  width: 44px;
  height: auto;
  margin: 0 10px;
  vertical-align: middle;
}

.bnb-layout-title {
  /*display: block;*/
  position: relative;
  /*font-family: BrandonTextWeb-Medium, sans-serif;
    font-weight: normal;
    font-style: normal;*/
  /*text-transform: uppercase;*/
  font-weight: 600;
  font-size: 1.3rem;
  bottom: -1px;
  color: #FF7F00;
  line-height: normal;
  opacity: .5;
}

.bnb-layout-title:hover {
  opacity: 1;
}

.bnb-button {
  background: 0 0;
  color: #111;
  font-size: 1rem;
  /*1.1rem*/
  margin: 3px 2px;
  min-width: 1em;
  /*height: 36px;*/
  /*mdl 36px*/
}

.bnb-button--raised {
  -webkit-box-shadow: 0 2px 2px 0 #c1c1c1, 0 3px 1px -2px #b0b0b0, 0 1px 5px 0 #e6e6e6;
  box-shadow: 0 2px 2px 0 #c1c1c1, 0 3px 1px -2px #b0b0b0, 0 1px 5px 0 #e6e6e6;
}

/* primary-accent #FF7F00 #ffab41 #F16600 #FFB300 #FFA032 #ffa500 #ff8c00 */

/* secondary-colored #ffaa00 #ff7853 #0080ff #3355ff #009688 #24b664 #FF5E32 #2196F3 */

/*colored*/

.bnb--color-colored {
  color: #ffaa00;
}

.bnb--bg-colored {
  background-color: #ffaa00;
}

.bnb-button--colored {
  color: #ffaa00;
}

.bnb-button--raised-colored,
.bnb-button--raised-colored:hover,
.bnb-button--raised-colored:focus,
.bnb-button--raised-colored:active {
  background-color: #ffaa00;
  color: #fafafa;
}

/*accent*/

.bnb--color-accent {
  color: #FF7F00;
}

.bnb--bg-accent {
  background-color: #FF7F00;
}

.bnb-button--accent {
  color: #FF7F00;
}

.bnb-button--raised-accent,
.bnb-button--raised-accent:hover,
.bnb-button--raised-accent:focus,
.bnb-button--raised-accent:active {
  background-color: #FF7F00;
  color: #fafafa;
}

/*calendar*/

.bnb-button--calendar {
  color: #009688;
}

/*reservation*/

.bnb--color-reservation {
  color: #FF7F00;
}

.bnb-button--reservation {
  color: #FF7F00;
}

.bnb-button--raised-reservation,
.bnb-button--raised-reservation:hover,
.bnb-button--raised-reservation:focus,
.bnb-button--raised-reservation:active {
  background-color: #FF7F00;
  color: #fafafa;
}

/* block */

.bnb-button--block {
  color: #808080;
}

.bnb-button--raised-block,
.bnb-button--raised-block:hover,
.bnb-button--raised-block:focus,
.bnb-button--raised-block:active {
  background-color: #808080;
  color: #fafafa;
}

/*police-data*/

.bnb-button--raised-police,
.bnb-button--raised-police:hover,
.bnb-button--raised-police:focus,
.bnb-button--raised-police:active {
  background-color: #3c7faf;
  color: #fafafa;
}

/*invoice*/

.bnb--color-invoice {
  color: #673ab7;
}

.bnb-button--invoice {
  color: #673ab7;
}

.bnb-button--raised-invoice,
.bnb-button--raised-invoice:hover,
.bnb-button--raised-invoice:focus,
.bnb-button--raised-invoice:active {
  background-color: #673ab7;
  color: #fafafa;
}

/*extra*/

.bnb-button--extra {
  color: #9c27b0;
}

/*planning*/

.bnb--color-planning {
  color: #38a1f4;
}

.bnb-button--planning {
  color: #38a1f4;
}

.bnb-button--raised-planning,
.bnb-button--raised-planning:hover,
.bnb-button--raised-planning:focus,
.bnb-button--raised-planning:active {
  background-color: #38a1f4;
  color: #fafafa;
}

/*support*/

.bnb--color-support {
  color: #2196f3;
}

.bnb-button--support {
  color: #2196f3;
}

.bnb-button--raised-support,
.bnb-button--raised-support:hover,
.bnb-button--raised-support:focus,
.bnb-button--raised-support:active {
  background-color: #2196f3;
  color: #fafafa;
}

/*dark*/

.bnb--color-dark {
  color: #444;
}

.bnb-button--dark {
  color: #444;
}

.bnb-button--raised-dark,
.bnb-button--raised-dark:hover,
.bnb-button--raised-dark:focus,
.bnb-button--raised-dark:active {
  background-color: #444;
  color: #fafafa;
}

/*lightgrey*/
.color-lightgrey,
.bnb--color-lightgrey {
  color: #999;
}

.bnb-button--lightgrey {
  color: #999;
}

.bnb-button--raised-lightgrey,
.bnb-button--raised-lightgrey:hover,
.bnb-button--raised-lightgrey:focus,
.bnb-button--raised-lightgrey:active {
  background-color: #999;
  color: #333;
}

/*light*/

.bnb--color-light {
  color: #e0e0e0;
}

.bnb--bg-light {
  background-color: #e0e0e0;
}

.bnb-button--light {
  color: #e0e0e0;
}

.bnb-button--raised-light,
.bnb-button--raised-light:hover,
.bnb-button--raised-light:focus,
.bnb-button--raised-light:active {
  background-color: #e0e0e0;
  color: #333333;
}

/*confirm*/

.bnb--color-confirm {
  color: #4CAF50;
}

.bnb--border-confirm {
  border-color: #4CAF50;
}

.bnb-button--confirm {
  color: #4CAF50;
}

.bnb-button--raised-confirm,
.bnb-button--raised-confirm:hover,
.bnb-button--raised-confirm:focus,
.bnb-button--raised-confirm:active {
  background-color: #4CAF50;
  color: #ffffff;
}


/*cancel*/

.bnb--color-cancel {
  color: #f44336;
}

.bnb--border-cancel {
  border-color: #f44336;
}

.bnb-button--cancel {
  color: #f44336;
}

.bnb-button--raised-cancel,
.bnb-button--raised-cancel:hover,
.bnb-button--raised-cancel:focus,
.bnb-button--raised-cancel:active {
  background-color: #f44336;
  color: #ffffff;
}

/*other*/

.bnb-button--small {
  height: 24px;
  padding: 0 8px;
  line-height: 24px;
  font-size: .9em;
}

.bnb-error-focus {
  color: #ff0000;
}

input.bnb-error-focus {
  color: #ff0000;
  border-color: #ff0000;
  /* 
  -webkit-box-shadow: inset 0 0 0 0 #f00 !important;
  box-shadow: inset 0 0 0 0 #f00 !important;
  */
}

.bnb-block-fullwidth {
  width: 100%;
  display: block;
}

.bnb--align-center {
  -ms-flex-item-align: center;
  align-self: center;
}

.bnb--border--radius-50 {
  border-radius: 50%;
}

.bnb--hide-important {
  display: none !important;
}

/*common-responsive*/

@media (max-width: 479px) {
  .bnb--hide-phone {
    display: none !important;
  }
}

@media (max-width: 839px) and (min-width: 480px) {
  .bnb--hide-tablet {
    display: none !important;
  }
}

@media (min-width: 840px) {
  .bnb--hide-desktop {
    display: none !important;
  }
}

@media (max-width: 1150px) {
  .bnb--hide-small-desktop {
    display: none;
  }
}

/*admin*/

.bnb-admin-instructions-text {
  color: #2196f3;
  /*color: #009688;*/
  /*font-weight: 300;*/
}

/*end_admin*/

/*calendar-input*/

.bnb-calendar-container {
  top: 3.5em;
}

/*tabs*/

/*.bnb-layout__tab-bar {
    background-color: inherit;
    padding: 0 8px;
    height: 100%;
    width: 100%;
    margin: 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow-y: hidden;
    overflow-x: hidden;
}*/

.bnb-layout__tab {
  /*float: left;*/
  position: relative;
  display: inline-block;
  /*
    display: flex;
    align-items: center;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    text-decoration: none;
    height: 48px;*/
  height: 100%;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: none;
  overflow: hidden;
  /*color: #777;*/
  border: none;
  border-bottom: 0 solid #ff7f00;
  /*margin: 0 4px;*/
  margin: 0;
  padding: 0 8px;
  background-color: #ffffff;
  min-width: 36px;
}

.bnb-layout__tab:hover {
  border-bottom-width: 3px;
  cursor: pointer;
  background-color: #f2f2f2;
  /*color: #000;*/
}

.bnb-tab-active {
  border-color: #ff7f00;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  /*background-color: #f2f2f2;*/
  color: #111;
}

/*end-tabs*/

/*flex*/

.bnb-layout-spacer {
  margin-left: auto;
  margin-right: auto;
}

/*sidebar-overlay*/

.bnb-sidebar-overlay {
  height: 100vh;
  width: 1080px;
  /*800px 990px*/
  min-width: 300px;
  /*320*/
  max-width: 100vw;
  position: fixed;
  z-index: 9;
  background-color: #fff;
  top: 0;
  right: 0;
  overflow-x: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-top: 0;
  border-left: solid 4px #424242;
  -webkit-box-shadow: 0 0 12px 2px #424242;
  box-shadow: 0 0 12px 2px #424242;
  -webkit-animation: 1s fadein;
  animation: 1s fadein;
  overflow: hidden;
  /* no more many vertical scrollbars */
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bnb-sidebar-overlay__header {
  height: 60px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #424242;
  /*border-bottom: 2px solid #ccc;*/
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 8px;
}

.bnb-sidebar-overlay-header__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 16px;
  font-size: 1.4rem;
  color: white;
  font-weight: bold;
}

.bnb-sidebar-overlay-header__close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  font-size: 1.2rem;
}

.bnb-sidebar-overlay-header__icon {
  text-decoration: none;
  color: #424242;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.bnb-sidebar-overlay-header__icon:hover {
  color: black;
}

.bnb-sidebar-overlay__content {
  position: absolute;
  top: 60px;
  width: 100%;
  bottom: 0;
  background-color: white;
}

.bnb-sidebar-overlay__iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

/*sidebar*/

.bnb-layout-sidebar {
  background-color: #eee;
}

/*menu*/

.bnb-menu-item__link {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: inherit;
}

/*top-actions*/

.bnb-nav-top-actions {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  margin-top: 0;
  margin-bottom: 0;
}

.bnb-top-actions__title {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 10px;
  margin-left: 0;
  /* font-weight: 500; */
}

/*public-security*/

.bnb-public-security-form-container {
  border: 2px solid #ccc;
  padding: 16px;
}

.bnb-form-input-number {
  width: 50px;
}

/*reservation-page*/

/*
.bnb-reservation-refund-policy-description {
  white-space: pre-line;
}
*/

/*container*/

.bnb-container-border--top-0 {
  border: solid #ccc;
  border-width: 0 1px 1px 1px;
}

/*FONTS*/

html,
body,
p,
.mdl-button,
.mdl-chip {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

html,
body,
p {
  font-size: 14px;
  /*15px*/
}

strong,
b {
  font-weight: bold;
  /*600*/
  font-style: normal;
}

.italic {
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: unset;
  /*TODO: check effects*/
}

/*end_fonts*/

/*index*/
/* 
.bnb-home-search-container {
  margin: 0 4vw;
  padding: 8px;
} 
*/

/*end_index*/

/*sweetalert*/

/*.sweet-alert.sweet-alert TODO: remove adjoining classes, now used to overwrite the standard css*/

.sweet-alert.sweet-alert {
  background-color: white;
  font-family: inherit;
}

.sweet-alert.sweet-alert button {
  font-family: inherit;
  /*sans-serif*/
  font-weight: inherit;
  font-style: inherit;
}

.sweet-alert.sweet-alert p {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: center;
}

.sweet-alert.sweet-alert h2 {
  color: inherit;
  font-weight: inherit;
}

.sweet-alert input:focus {
  border: none;
}

@media (max-width: 640px) {
  .sweet-alert h2 {
    font-size: 24px;
    /*base 30*/
    line-height: 30px;
    /*base 40*/
    margin: auto;
  }

  .sweet-alert p {
    font-size: 14px;
    /*base 16*/
  }

  .sweet-alert button {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

/*sweetalert-end*/

/** swal override */

.sweet-alert button, 
.swal-button, 
.swal-button--confirm, 
button.swal2-styled.swal2-confirm {
  background-color: #2196f3;
  color: #fff;
}

.swal-button--cancel,
button.swal2-styled.swal2-cancel {
  background-color: #eee;
  color: #666;
}

.swal-button.bnb-button--raised-reservation {
  background-color: #ff7f00;
  color: #fafafa;
}
.swal-button.bnb-button--raised-block {
  background-color: #808080;
  color: #fafafa;
}

/* swal end */

/*su*/

.bnb-superuser {
  background-color: LavenderBlush;
}

/*end_su*/

/*hr*/

.ciao-hr-light {
  border-top: 2px dashed #ccc;
}

.ciao-hr-lighter {
  border-top: 1px dashed #ccc;
}

.ciao-hr-medium {
  border-top: 2px solid #ddd;
}

.ciao-divider {
  border-top: 1px dashed #ccc;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.ciao-divider-top {
  border-top: 1px solid #ccc;
  margin-top: 1rem;
  padding-top: 1rem;
}
.ciao-divider-bottom {
  border-bottom: 1px solid #ccc;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.ciao-divider-left {
  border-left: 1px dashed #ccc;
  margin-left: 1rem;
  padding-left: 1rem;
}

/*end_hr*/

/* borders */
.ciao-border-top-light {
  border-top: 1px solid #ccc;
}
.ciao-border-bottom-lighter {
  border-bottom: 1px dashed #ccc;
}

/*iubenda*/

/*  GENERAL CLASS */

#iubenda-cs-banner {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: #000;
}

/*  CONTENT CLASS */

.iubenda-cs-content {
  display: block;
  margin: 0;
  padding: 16px;
  font-size: 14px;
  background: #424242;
  color: #fafafa;
}

.iubenda-cs-rationale {
  max-width: 900px;
  position: relative;
  margin: 0 auto;
}

/*.iubenda-banner-content {}*/

/*   STYLE FOR TEXT INTERNAL BANNER */

.iubenda-banner-content>p {
  line-height: 1.5;
}

/*  CLOSE BUTTON. NOTICE: IMAGE FOR BUTTON NOT IMPLEMENTED YET */

.iubenda-cs-close-btn {
  margin: 0;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #fff;
  padding: 8px 16px;
}

/*  STYLE FOR TEXT LINK TO PRIVACY POLICY */

.iubenda-cs-cookie-policy-lnk {
  text-decoration: underline;
  color: #fff;
}

/*end_iubenda*/

/*ciao-margins*/

.ciao--margins-left-right-0 {
  margin-left: 0;
  margin-right: 0;
}

.ciao--margins-top-bottom-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.ciao--margins-top-bottom-medium {
  margin-top: 8px;
  margin-bottom: 8px;
}

.ciao--margins-top-bottom-small {
  margin-top: 4px;
  margin-bottom: 4px;
}

.ciao--margin-0 {
  margin: 0;
}

.ciao--margin-left-0 {
  margin-left: 0;
}

.ciao--margin-bottom-0 {
  margin-bottom: 0;
}

.ciao--margin-bottom-small {
  margin-bottom: 4px;
}

.ciao--margin-top-small {
  margin-top: 4px;
}

.ciao--margin-top-0 {
  margin-top: 0;
}

.ciao--margin-0-medium {
  margin: 0 8px;
}

.ciao--margin-medium-0 {
  margin: 8px 0;
}

.ciao--margin-top-medium {
  margin-top: 8px;
}

.ciao--margin-bottom-medium {
  margin-bottom: 8px;
}

.ciao--margin-left-medium {
  margin-left: 8px;
}

.ciao--margin-right-medium {
  margin-right: 8px;
}

.ciao--margins-medium {
  margin: 8px;
}

/*end_ciao-margins*/

/*ciao-padding*/

.ciao--padding-left-right-0 {
  padding-left: 0;
  padding-right: 0;
}

.ciao--padding-top-bottom-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.ciao--padding-0 {
  padding: 0;
}

.ciao--padding-bottom-0 {
  padding-bottom: 0;
}

.ciao--padding-bottom-small {
  padding-bottom: 4px;
}

.ciao--padding-top-0 {
  padding-top: 0;
}

.ciao--padding-0-medium {
  padding: 0 8px;
}

.ciao--padding-medium {
  padding: 8px;
}

.ciao--padding-left-right-medium {
  padding-left: 8px;
  padding-right: 8px;
}

/*end_ciao-paddings*/

/*manual-reservation*/

.ciaoManualReservation-container {
  /*outline: 0.2em dashed #e0e0e0;*/
  outline: 1px solid #e0e0e0;
}

.ciaoManualReservation-title {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 16px;
}

/*end_manual-reservation*/

/*registration*/

.ciaoGuestCard-title {
  font-weight: bold;
  display: inline-block;
}

/*end_registration*/

/*billing*/

.ciao-input-bold {
  font-weight: bold;
}

/*end_billing*/

/*confirm-reservation*/

.ciao--uppercase {
  text-transform: uppercase;
}

.ciao--underline {
  text-transform: underline;
}

.ciao-container--light-border {
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ciao--bigger-font {
  font-size: 120%;
}

.ciao--bold,
.font-bold {
  font-weight: bold;
}

.ciao--semibold,
.ciao-font-semibold,
.font-semibold {
  font-weight: 600;
}

.ciao-customer-inputs__input {
  width: 100%;
}

.ciao-a--no-decoration-important {
  text-decoration: none !important;
}

.ciao-customer-inputs__input-container {
  margin-bottom: 8px;
}

.ciao--color-green {
  color: #43A047;
}

.ciao--text-center {
  text-align: center;
}

.ciao--text-right {
  text-align: right;
}

.ciao--text-left {
  text-align: left;
}

.ciao--vertical-middle {
  vertical-align: middle;
}

.ciao--no-box-shadow {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ciao--overflow-x-auto,
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-hidden {
  overflow: hidden;
}
/*end_confirm-reservation*/

/*table-billing*/

.ciao-dt-table-container--style table.dataTable,
.dataTables_info {
  font-size: 12px;
}

.ciao-dt-table-container--style .dataTables_wrapper {
  border: 1px solid #aaa;
  margin: 8px 0;
}

.ciao-dt-table-container--style .dataTables_wrapper .dataTables_info {
  padding: 0 4px;
}

/*datatable use class "compact" to have padding 4px */

/* 
.ciao-dt-table-container--style table.dataTable tbody th,
.ciao-dt-table-container--style table.dataTable tbody td {
  padding: 4px;
} 
*/
/* 
table.dataTable thead th, 
table.dataTable thead td {
  padding: 10px 18px;
  border-bottom: 1px solid #111;
} 
*/
.ciao-dt-table-container--style table.dataTable thead th,
.ciao-dt-table-container--style table.dataTable thead td {
  /* padding: 4px; */
  border: 0;
}

.ciao-dt-table-container--style table.dataTable tfoot th,
.ciao-dt-table-container--style table.dataTable tfoot td {
  /*padding: 10px 18px 6px 18px;*/
  padding: 4px;
  border-top: none;
  /*overwrite dt*/
  border-bottom: 1px dashed #aaa;
}

.ciao-dt-table-container--style .dataTables_scrollHeadInner {
  border-bottom: 1px solid #aaa;
}

.ciao-dt-table-container--style .dataTables_scrollFootInner {
  border-top: 1px solid #aaa;
}

/*datatables: do not highlight sorting column - EDIT: do not use "display" or "order-column" class for datatables */

/*
.ciao-dt-table-container--style table.dataTable td.sorting_1 {
  background-color: #ffffff !important;
}
*/

.ciao-dt-table__tr-headers {
  background-color: #eee;
}

.ciao-dt-table__tr-filters {
  background-color: #eee;
}

.ciao-dt-table-container--style .dataTables_scrollHead {
  background-color: #eee;
}

.ciao-dt-table-container--style table.dataTable thead tr.ciao-dt-table__tr-headers>th,
.ciao-dt-table__tr-headers__th {
  border: 0;
  border-bottom: 1px solid #aaa;
  border-right: 1px dashed #aaa;
}

/*
.ciao-dt-table-container--style table.dataTable thead tr.ciao-dt-table__tr-headers>th:last-child {
  border-right: 0;
}
*/

/* compact: padding-right 17px for arrows */
.ciao-dt-table-container--style table.dataTable.compact thead th,
.ciao-dt-table-container--style table.dataTable.compact thead td {
  padding: 4px;
}

.ciao-dt-table-container--style table.dataTable thead tr.ciao-dt-table__tr-filters>td {
  border: 0;
  border-right: 1px dashed #aaa;
  padding: 4px;
  /*even if table is not "compact" */
}

.ciao-dt-table-container--style table.dataTable.hover tbody tr:hover,
.ciao-dt-table-container--style table.dataTable.display tbody tr:hover {
  background-color: #f5f5f5;
}

/* 
.ciao-dt-table-container--style .dataTables_scrollHead td:first-child,
.ciao-dt-table-container--style .dataTables_scrollBody td:first-child {
  border-right: 1px dashed #aaa;
} 
*/

td.ciao-td-check {
  border-right: 1px dashed #aaa;
}

/* ciao-table */
.ciao-table {
  border: 1px solid #ccc;
}
.ciao-table input,
.ciao-table select {
  max-width: 100%;
}
.ciao-table__header {
  font-weight: bold;
  background: #eee;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  z-index: 1;
}
.ciao-table__header-row > * {
  padding: 1rem .5rem;
}
.ciao-table__header-row > * + * {
  border-left: 1px dashed #ccc;
}
.ciao-table__body {

}
.ciao-table__body-row > * {
  padding: .25rem .5rem;
  border-bottom: 1px solid #ccc;
}
.ciao-table__body-row > * + * {
  border-left: 1px dashed #ccc;
}

.ciao-table-fixed {
  table-layout: fixed;
}

.ciao-table-border {
  border: 1px solid #aaa;
}

/* updated table v2 with sticky header */
.ciao-table-v2-container {
  border: 1px solid #eee;
  overflow: auto;
}
.ciao-table-v2 {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ciao-table-v2 > thead > tr > th {
  text-transform: uppercase;
  color: #6b7280;
  letter-spacing: 0.05em;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  background-color: #fafafa;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #eee;
  text-align: left;
}
.ciao-table-v2 > tbody {
  background-color: #fff;
}
.ciao-table-v2 > tbody > tr > td {
  white-space: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-bottom: 1px solid #eee;
  text-align: left;
}
.ciao-table > tbody > tr:last-of-type > td {
  border-bottom: 0;
}

/*end_table-billing*/

/*edit and pricing*/

.bnb-pricing-cell-container {
  outline: 3px solid #e0e0e0;
}

.bnb-container--margin-bottom-footer {
  margin-bottom: 47px;
}

.bnb-edit-footer,
.bnb-pricing-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: 0;
  height: 60px;
  left: 0;
  right: 0;
  z-index: 20;
}

.bnb-edit-footer__content,
.bnb-pricing-footer__content {
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-bottom: 0;
}

.bnb-edit-nav__link,
.bnb-pricing-nav__link {
  padding: 4px;
}

/*end_edit and pricing*/

/*hint container*/

.ciao-hint-container {
  padding: 12px 8px;
  margin: 8px 0;
  border: solid 1px;
  border-left: solid 8px;
}

.ciao-hint-container .bnb-admin-instructions-text,
.ciao-hint-container.bnb-admin-instructions-text {
  color: #111111;
}

.ciao-hint-container--support {
  border-color: var(--support-color);
  background-color: #ecf4f9;
}

.ciao-hint-container--confirm {
  border-color: #4caf50;
  background-color: #e7fee8;
}

.ciao-hint-container--note {
  border-color: #fdd835;
  background-color: #fdf6d8;
}

.ciao-hint-container--custom {
  border-color: #e91e63;
  background-color: #fdd8e4;
}

.ciao-hint-container--error {
  border-color: #f44336;
  background-color: #feedec;
}


/*end_hint container*/

/*PRINT*/

@media print {

  nav,
  aside {
    display: none;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  a[href]:after {
    content: none !important;
  }
}

/*end_print*/

/*OTHER*/

.ciao-container--dashed {
  border: 1px dashed #cccccc;
  margin: 8px 0;
  padding: 8px 0;
}

.ciao-label--block {
  display: block;
  margin-top: 6px;
  margin-bottom: 2px;
}

.ciao-input-inline-small {
  display: inline;
  max-width: 50px;
  text-align: right;
}

.ciao-input-inline-medium {
  display: inline;
  max-width: 70px;
  text-align: right;
}

.ciao-input-inline-large {
  display: inline;
  max-width: 400px;
  /*width: 400px;*/
}

@media screen and (max-width: 1024px) {
  .sweet-alert.sweet-alert .sa-icon {
    margin: 5px auto;
  }

  .sweet-alert.sweet-alert h2 {
    margin: 0;
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 1.2;
  }

  .sweet-alert.sweet-alert button {
    margin-top: 12px;
  }
}

::-webkit-input-placeholder {
  color: #808080;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #808080;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #808080;
  opacity: 1;
}

::placeholder {
  color: #808080;
  opacity: 1;
}

.ciao-icon-svg {
  height: 1.2em;
  width: 1.2em;
  vertical-align: text-bottom;
  fill: currentColor;
}

.fill-current {	
  fill: currentColor;
}

/* input */
.ciao-input-changed,
input.ciao-input-changed,
.ciao-input--dirty,
input.ciao-input--dirty {
  /* background: #fec; */
  background: #f5eee0!important;
  color: var(--accent-color);
  font-weight: 600;
}

/*
  height: 17px;
  width: 17px;
*/

/* tags */

/*
.bnb-tags {
  font-size: 12px !important;
  overflow: hidden;
}
*/

.bnb-tags {
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  color: #111;
  /* some from mdl-chip */
  height: 24px;
  /* line-height: 24px; */
  padding: 0 10px;
  border-radius: 16px;
  display: inline-block;
  margin: 0 1px;
  white-space: nowrap;
  font-size: 13px;
  overflow: hidden;
  cursor: pointer;
}

.bnb-reservation-origins {
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  color: #111;
  /* some from mdl-chip */
  height: 24px;
  /* line-height: 24px; */
  padding: 0 10px;
  border-radius: 16px;
  display: inline-block;
  margin: 0 1px;
  white-space: nowrap;
  font-size: 13px;
  overflow: hidden;
  cursor: pointer;
}

.bnb-tags .bnb-tags__text {
  font-size: 12px;
  vertical-align: unset;
  /* middle */
  display: inline-block;
}

.bnb-reservation-origins .bnb-reservation-origins__text {
  font-size: 12px;
  vertical-align: unset;
  /* middle */
  display: inline-block;
}

.tags-selected {
  /* tags */
  /* background: Orange; */
  background: #ffaa00;
  color: #ffffff;
  border-color: #ffaa00;
}

.reservation-origins-selected {
  /* reservation origins */
  /* background: Orange; */
  background: #ffaa00;
  color: #ffffff;
  border-color: #ffaa00;
}

/* chosen */
.chosen-container-single .chosen-single {
  height: 25px;
  line-height: 24px;
  -webkit-box-shadow: rgb(255, 255, 255) 0px 0px 3px inset, rgba(0, 0, 0, 0.1) 0px 1px 1px;
  box-shadow: rgb(255, 255, 255) 0px 0px 3px inset, rgba(0, 0, 0, 0.1) 0px 1px 1px;
  padding: 0px 0px 0px 8px;
  border-color: rgb(170, 170, 170);
  border-radius: 4px;
  /* background: linear-gradient(rgb(255, 255, 255) 20%, rgb(246, 246, 246) 50%, rgb(238, 238, 238) 52%, rgb(244, 244, 244) 100%) padding-box; */
  background: #ffffff;
}

/*  dialog polyfill - https://github.com/GoogleChrome/dialog-polyfill/ */
dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: 2px solid #999;
  padding: 1em;
  background: #fafafa;
  color: #333;
  display: block;
  /* border-width: 3px; */
  border-radius: 2px;
}

dialog:not([open]) {
  display: none;
}

dialog+.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

dialog.fixed {
  position: fixed;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

/* end dialog polyfill */

/* custom jquery-ui */
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  border-color: none;
  background: #2196f3;
  font-weight: normal;
  color: #ffffff;
}

.ui-icon-background,
.ui-state-active .ui-icon-background {
  border: #2196f3;
  background-color: #ffffff;
}

/* css hacks */
.ciao-vertical-unset-important {
  vertical-align: unset !important;
}

.mdl-mini-footer a {
  color: inherit;
  margin-left: 8px;
}

img[data-selected='true'] {
  outline-style: solid;
  outline-color: var(--accent-color);
  outline-offset: -.5rem;
  outline-width: .5rem;
}

.ciao-checkbox--medium {
  width: 14px;
  height: 14px;
}

/* margin for iphone - same height of iframe top header */
.ciao-margin-bottom-for-phone {
  margin-bottom: 60px;
}

/* default text hidden */
.bnb-language-menu__text {
  display: none;
}

/* ciao-badge */
/* 
.ciao-badge[data-badge]:after {
  content: attr(data-badge);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 9px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ff7f00;
  color: #ffffff;
} 
*/

/* select2 over */
/* 
.select2-container--default .select2-selection--single {
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
}
.select2-container .select2-selection--single {
  height: 35px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 34px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 35px;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #808080;
}
.select2-search--dropdown .select2-search__field {
  padding: 6px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #bfbfbf;
}
.select2-container--default .select2-search--dropdown .select2-search__field:hover {
  border-color: #ffaa00;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  color: #f44336;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #212121 transparent transparent transparent;
} 
*/

/* ciao-menu with relative mdl-menu */
#bnb-owner, 
#bnb-language, 
#bnb-login {
  position: relative;
}

/* link style */
.ciao--linkstyle {
  background: none;
  border: none;
  padding: 0;
  color: var(--accent-color);
  cursor: pointer;
}
.ciao--linkstyle:hover {
  text-decoration: underline;
}
.ciao--linkstyle.bnb--color-cancel {
  color: var(--cancel-color);
}

.cursor-pointer {	
  cursor: pointer;
}

/* ellipsis */
.ciao--ellipsis,
.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ciao--overflow-hidden {
  overflow: hidden;
  white-space: nowrap;
}

.ciao-overflow-y-overlay {
  overflow-y: overlay!important;
}

/* ciao-layout - inspiration: tailwindcss */
.ciao-block,
.block {
  display: block;
}
.ciao-flex,
.flex {
  display: flex;
}
.ciao-flex-wrap,
.flex-wrap {
  flex-wrap: wrap;
}
.ciao-flex-col,
.flex-col {
  flex-direction: column;
}
.ciao-flex-1,
.flex-1 {
  flex: 1 1 0%;
}
.ciao-inline-flex,
.inline-flex {
  display: inline-flex;
} 
.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-shrink {
  flex-shrink: 1;
}

.ciao-justify-between,
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.ciao-parentof-flex-columns > * {
  display: flex;
  flex-direction: column;
}
.ciao-parentof-flex-rows > * {
  display: flex;
  flex-direction: rows;
}
.ciao-grid-translations {
  display: grid;
  grid-template-columns: 2em 1fr; /* 2 columns: first element (it:) 3em, second element (input) remaining fraction */
  align-items: center;
  gap: .5rem;
}
.ciao-grid-translations > span {
  text-transform: uppercase;
  text-align: center;
}
.items-start {
  align-items: flex-start;
}
.ciao-items-center,
.items-center {
  align-items: center;
}
.ciao-parentof-items-center > * {
  align-items: center;
}
.ciao-items-end,
.items-end {
  align-items: flex-end;
}
.ciao-items-baseline {
  align-items: baseline;
}
.ciao-content-justify-flex-end {
  justify-content: flex-end;
}
.self-center {
  align-self: center; 
}
.ciao-section {
  width: 100%;
  margin: 8px;
}
/* space all children after first */
.ciao-space-x-medium > * + * {
  margin-left: 0.5rem;
}
.ciao-space-x-medium-all * + * {
  margin-left: 0.5rem;
}
.ciao-space-x-large > * + * {
  margin-left: 1rem;
}
.ciao-space-y-medium > * + * {
  margin-top: 0.5rem;
}
.ciao-w-1_2 {
  width: 50%;
}
.max-w-screen-1\/2 {
  max-width: 50vw;
}
.ciao-w-1_3 {
  width: 33.33%;
}
.ciao-w-2_3 {
  width: 66.67%;
}
.w-4 {
  width: 1rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.w-6 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.h-6 {
  height: 1.5rem;
}
.ciao-w-xs,
.w-8 {
  width: 2rem;
}
.h-8 {
  height: 2rem;
}
.w-12	{
  width: 3rem;
}
.w-14	{ 
  width: 3.5rem; 
}
.ciao-w-small,
.ciao-w-sm,
.w-16 {
  width: 4rem;
}
.w-20	{
  width: 5rem;
}
.h-16 {
  height: 4rem;
}
.min-w-16 {
  min-width: 4rem;
}
.h-20 {	
  height: 5rem; 
}
.w-24	{
  width: 6rem;
}
.min-w-24	{
  min-width: 6rem;
}
.max-w-24	{
  max-width: 6rem;
}
.h-24	{
  height: 6rem;
}
.ciao-w-medium,
.ciao-w-md,
.w-32 {
  width: 8rem;
}
.max-w-32 {
  max-width: 8rem;
}
.min-w-32 {
  min-width: 8rem;
}
.ciao-w-lg,
.w-48 {
  width: 12rem;
}
.max-w-48 {
  max-width: 12rem;
}
.max-w-3xl	{
  max-width: 48rem;
}
.max-h-48	{
  max-height: 12rem;
}
.min-w-48 {
  min-width: 12rem;
}
.ciao-w-xl,
.w-64 {
  width: 16rem;
}
.h-full {
  height: 100%;
}
.h-screen	{
  height: 100vh;
}
.ciao--fullwidth,
.ciao-w-full,
.w-full {
  width: 100%;
}
.ciao-w-screen {
  width: 100vw;
}
.ciao-w-auto,
.w-auto {
  width: auto;
}
.ciao-w-max-full,
.max-w-full {
  max-width: 100%;
}
.h-10	{
  height: 2.5rem;
}
.h-12 {	
  height: 3rem;
}
.ciao-pt-medium {
  padding-top: .5rem;
}
.ciao-text-left,
.text-left {	
  text-align: left; 
}
.ciao-text-center,
.text-center	{
  text-align: center;
}
.ciao-text-right,
.text-right {
  text-align: right;
}
.ciao-text-justify,
.text-justify {
  text-align: justify;
}
.ciao-text-base {
  font-size: 1rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem; 
}
.ciao-text-xs {
  font-size: .75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.ciao-text-lg,
.text-lg {
  font-size: 1.125rem;;
}
.ciao-text-xl {
  font-size: 1.25rem;;
}
.text-xl	{
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-2xl	{
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl { 
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-4xl {	
  font-size: 2.25rem;
  line-height: 2.5rem; 
}
.ciao-capitalize,
.capitalize {
  text-transform: capitalize;
}

/* CHIP ispired by tailwindcss: px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 */
.ciao-leading-5 {
  line-height: 1.25rem;
}
.ciao-leading-tight {
  line-height: 1.25;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-md {
  border-radius: .375rem;
}

.rounded-b-none	{
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.rounded-t-none	{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.ciao-rounded-full {
  border-radius: 9999px;
}
.rounded-lg	{
  border-radius: 0.5rem;
}
.ciao-bg-yellow-100 {
  background-color: rgba(254, 243, 199, 1);	
} 
.ciao-bg-blue-100 {
  background-color: rgba(219, 234, 254, 1);	
}
.ciao-bg-green-100 {
  background-color: rgba(209, 250, 229, 1);	
}
.ciao-bg-red-100 {
  background-color: rgba(254, 226, 226, 1);	
}
.ciao-bg-gray-100,
.bg-gray-100 {
  background-color: rgba(243, 244, 246, 1);	
}
.ciao-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

/* some style copied from tailwindcss.com */
.ml-auto { 
  margin-left: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ml-0 {
  margin-left: 0;
}
.m-1 {
  margin: 0.25rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mb-2 {	
  margin-bottom: 0.5rem; 
}
.mt-2 {	
  margin-top: 0.5rem; 
}
.ml-3 {
  margin-left: 0.75rem;
}
.m-4 {
  margin: 1rem;
}
.mr-4 {
  margin-right: 1rem;
}
.ml-4 {	
  margin-left: 1rem; 
}
.mt-4 {	
  margin-top: 1rem; 
}
.mb-4 {	
  margin-bottom: 1rem; 
}
.mt-5	{
  margin-top: 1.25rem;
}
.-my-6	{
  margin-top: -1.5rem; 
}
.p-0\.5 {
  padding: 0.125rem;
}
.p-0\! {
  padding: 0!important;
}
.p-1 {
  padding: .25rem;
}
.px-1,
input[type=text].px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.pl-1 {
  padding-left: .25rem;
} 
.pr-1 {
  padding-right: .25rem;
}
.p-2 {
  padding: .5rem;
}
.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pt-2 {
  padding-top: .5rem;
}
.pb-2 {
  padding-bottom: .5rem;
}
.p-4 {
  padding: 1rem;
}
.px-4 {	
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.p-8	{
  padding: 2rem;
}
.space-y-1 > * + * {
  margin-top: .25rem;
}
.space-y-2 > * + * {
  margin-top: .5rem;
}
.space-y-4 > * + * {
  margin-top: 1rem;
}
.space-y-6 > * + * {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.space-x-2 > * + * {
  margin-left: .5rem;
}
.space-x-4 > * + * {
  margin-left: 1rem;
}
.-space-x-px > * + *	{
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}
.ciao-border-light {
  border: 1px solid #e0e0e0;
}
.inline-block	{ 
  display: inline-block;
}
.bg-gray-300 {
  background-color: rgba(209, 213, 219, 1);	
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.text-white {
  color: #fff;
}
.bg-white {
  background-color: #fff;
}
.ciao-bg-lighter {
  background-color: #eee;
}
.bg-transparent,
input.bg-transparent	{ 
  background-color: transparent; 
}
.border-transparent,
input.border-transparent	{
  border-color: transparent;
}
.hidden {
  display: none;
}
.grid {
  display: grid;
}
.grid-cols-1	{ 
  grid-template-columns: repeat(1, minmax(0, 1fr)); 
}
.grid-cols-2	{ 
  grid-template-columns: repeat(2, minmax(0, 1fr)); 
}
.grid-cols-3	{ 
  grid-template-columns: repeat(3, minmax(0, 1fr)); 
}
.grid-cols-4	{ 
  grid-template-columns: repeat(4, minmax(0, 1fr)); 
}
.grid-cols-5	{
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-6	{
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-cols-7	{
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid-cols-8	{
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
.col-span-1 {
  grid-column: span 1 / span 1;
}
.col-span-2 {	
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.col-span-6 {
  grid-column: span 6 / span 6;
}
.gap-x-2	{ 
  column-gap: 0.5rem; 
}
.gap-x-4 {
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}
.gap-y-2 {	
  row-gap: 0.5rem; 
}
.gap-y-4 {	
  row-gap: 1rem; 
}


.shadow {
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.rounded-none {
  border-radius: 0px;
} 
.rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}
.rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}
.rounded-tl-md {
  border-top-left-radius: 0.375rem;
}
.rounded-tr-md {
  border-top-right-radius: 0.375rem;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.min-w-0 {
  min-width: 0;
}
.break-words {
  overflow-wrap: break-word;
}

.border-t {
  border-top-width: 1px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.divide-y > * + * {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-200 > * + * {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.font-medium {
  font-weight: 500;
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.select-none {
  user-select: none;
}

.ciao-table-users > tbody > tr > td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

/* disabled input */
input[disabled='true'], 
input[disabled='disabled'], 
input[disabled],
input.disabled,
textarea[disabled='true'], 
textarea[disabled='disabled'], 
textarea[disabled],
textarea.disabled {
    /* background-color: #fafafa; */
    cursor: not-allowed;
}

/* swal radio override */
.ciao-swal-radio-column .swal2-radio {
  flex-direction: column;
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, 1);	
}
.active\:bg-red-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.text-gray-700:hover{
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.hover\:text-gray-900:hover{
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.hover\:bg-gray-300:hover{
  background-color: rgba(209, 213, 219, 1);	
}
.hover\:bg-gray-900:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));	
}

.static	{
  position: static;
}
.fixed	{
  position: fixed;
}
.absolute	{
  position: absolute;
}
.relative	{
  position: relative;
}
.sticky	{
  position: sticky;
}

.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}

.invalid-input {
  border: 1px solid red;
}
.error-text {
  color: red;
}
.sm\:px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover	{
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.active\:bg-gray-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, 1);	
}
.bg-gray-800 {
  background-color: #2d3748;
}

.border {
  border-width: 1px;
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:ring-2:focus {
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
  box-shadow: 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color), var(--tw-ring-shadow);
}

.focus\:ring-gray-900:focus {
  --tw-ring-color: rgba(17, 24, 39, 1);
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.focus\:ring-indigo-500:focus	{
  --tw-ring-color: rgba(99, 102, 241, 1);
}
.text-indigo-700 {
  color: #4c51bf;
}

.bg-indigo-100 {
  background-color: #ebf4ff;
}
.hover\:bg-indigo-200:hover{
  background-color: #c3dafe;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}

.top-0 {
  top: 0px;
}

.top-2 {
  top: 0.5rem;
}
.bottom-2 {
  bottom: 0.5rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.m-0 {
  margin: 0px;
}
.top-18 {
  top: 4.5rem;
}

/* small breakpoint */
@media (min-width: 640px) {
  .sm\:grid-cols-2	{ 
    grid-template-columns: repeat(2, minmax(0, 1fr)); 
  }
  .sm\:grid-cols-3	{ 
    grid-template-columns: repeat(3, minmax(0, 1fr)); 
  }
  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5,minmax(0,1fr));
  }
  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6,minmax(0,1fr));
  }
  .sm\:col-span-2 {	
    grid-column: span 2 / span 2;
  }
  .sm\:order-1 {
    order: 1;
  }
  .sm\:order-2 {
    order: 2;
  }
}
/* medium breakpoint */
@media (min-width: 768px) { 
  .md\:grid {
    display: grid;
  }
  .md\:grid-cols-2	{ 
    grid-template-columns: repeat(2, minmax(0, 1fr)); 
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5,minmax(0,1fr));
  }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6,minmax(0,1fr));
  }
  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .md\:order-1 {
    order: 1;
  }
  .md\:order-2 {
    order: 2;
  }
  .md\:ml-4 {
    margin-left: 1rem; 
  }
  .md\:block {
    display: block!important;
  }
}
/* large breakpoint */
@media (min-width: 1024px) {
  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6,minmax(0,1fr));
  }
  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12,minmax(0,1fr));
  }
  .lg\:ciao-w-1_3 {
    width: 33.33%;
  }
  .lg\:ciao-w-1_2 {
    width: 50%;
  }
  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
/* extralarge breakpoint */
@media (min-width: 1280px) {
  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6,minmax(0,1fr));
  }
  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12,minmax(0,1fr));
  }
}
.no-underline {
  text-decoration: none;
}

.hover\:underline:hover {
  text-decoration: underline;
}
input,
textarea {
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  padding: 6px 8px;
  font-family: inherit;
  font-size: inherit;
}

textarea {
  resize: vertical;
}